<template>
	<div id="app">
		<router-view />

		<ByDialog
			class="clear-cart-confirm"
			:value="isCancelOrderDialog"
			width="320px"
			title="您的餐點已被取消"
			@closeDialog="onCloseCancelOrderDialog"
		>
			<template slot="dialog-footer">
				<div class="dialog-footer">
					<ByButton stl="main1" @click="onCloseCancelOrderDialog"
						>好的
					</ByButton>
				</div>
			</template>
		</ByDialog>
	</div>
</template>
<script>
import ByDialog from '@/components/Dialog.vue'
import ByButton from '@/components/Button.vue'

export default {
	components: { ByButton, ByDialog },
	data() {
		return {
			getContactLoad: true,
			getWebSocketLoad: true,
      pageTitle: '點溡線上點餐'
		}
	},
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        { property: 'og:title', content: this.pageTitle , 'data-react-helmet': true }
      ]
    }
  },
	computed: {
		isGuest() {
			return this.$store.state.user?.guestId
		},
		isCancelOrderDialog() {
			return this.$store.state.isCancelOrderDialog
		},
		storeId() {
			return this.$store.state.user.storeId
		},
	},
	watch: {
		async '$route.params.random'(e, v) {
			const kiosk = this.$route.query.kiosk
			if (kiosk && kiosk === e) {
				await this.getStore()
				return
			}
			if (e && e !== v) {
				await this.getStore()
				await this.checkBuyer()
			}
			if (!!!v) {
				// 首次進入
				if (this.$store.state.uri === null) {
					this.goContact()
				}

				this.initWebSocket()
				if (!this.$store.getters.isKiosk) {
					await this.getOpenInfo()
          this.checkWhatsEatBinding()
				}
			}
		},
		async '$route.path'(e, v) {
			if (e && e !== v && v !== '/') {
				if (this.$route.meta.requiredStore && !this.$store.getters.isKiosk) {
					if (this.storeId && this.isGuest) {
						await this.getOpenInfo()
					}
				}
			}
		},
		async '$store.state.store.refreshSetting'(val, beforeVal) {
			if (val) {
				await this.getOpenInfo()
				this.$store.commit('store/SET_REFRESH_SETTING', false)
        this.checkWhatsEatBinding()
			}
		},
	},
	async created() {
		this.$store.commit('user/SET_STORE', { storeId: null, storeName: '' })
		document.title = '點溡點餐平台'
		this.goContact()
		const qrCodeHash = this.$route.params.random
		if (qrCodeHash) {
			await this.getStore()
			if (this.$store.state.maintenance.modal) {
				return
			}
			const kiosk = this.$route.query.kiosk === qrCodeHash
			if (!kiosk) {
				await this.checkBuyer()
				if (!this.$store.getters.isKiosk) {
					this.initWebSocket()
					await this.getOpenInfo()
				}
			}
		}
	},
	methods: {
		// 聯絡點溡
		goContact() {
			this.$api.getUrl().then(res => {
				if (res.data) {
					this.$store.commit('store/SET_URI', res.data)
				}
			})
		},
		// 辨別店家id
		async getStore() {
			const qrCodeHash = this.$route.params.random
			await this.$api.qrCode
				.getStore({ qrCodeHash })
				.then(res => {
					if (res.status) {
						const storeName = res.data.name
						this.$store.commit('user/SET_STORE', {
							storeId: res.data.id,
							storeName: storeName,
						})
						document.title = storeName ? storeName : '點溡點餐平台'
            this.pageTitle = storeName ? storeName + ' | 線上點餐' : '點溡點餐平台'

						this.$store.commit('user/CHECK_CART_AND_SET')
					} else {
						this.handleGetStoreIdFail(res.message)
					}
				})
				.catch(err => {
					if (err === '服務更新中') {
						return
					}
					this.handleGetStoreIdFail(err)
				})
		},
		handleGetStoreIdFail(message) {
			this.$alert(message, 'danger')
			this.$store.commit('user/SET_STORE', { storeId: null, storeName: '' })
			document.title = '點溡點餐平台'
			this.$router.replace('/null/Error')
		},
		async checkBuyer() {
			if (
				this.$store.getters.isKiosk ||
				this.$route.path === `/${this.$route.params.random}/Refresh`
			) {
				await this.handleUserBuyer()
			} else {
				await this.handleGuestBuyer()
			}
		},
		async handleUserBuyer() {
			const userLogin = await this.$api.user.getCurrentBuyer()
			const { errorCode, data } = userLogin
			if (errorCode === 'SUCCESS') {
				if (data.loginType === 'FRONT') {
					if (this.judgeHasStorePermission(data)) {
						this.$store.commit('kiosk/SET_IS', true)

						if (this.$route.path !== `/${this.$route.params.random}/Store`) {
							await this.$router.replace(`/${this.$route.params.random}/Store`)
						}
						return
					}
				}
			}

			await this.$api.user.logout()
			this.$store.commit('kiosk/SET_IS', false)
			this.$store.commit('user/SET_GUEST_ID', null)
			this.$store.commit('user/SET_USER_NAME', '')
			this.$store.commit('user/SET_USER_PHONE', '')
			this.$store.commit('user/SET_USER_ADDRESS', '')
			this.$store.commit('user/SET_XSRF_TOKEN', '')
			await this.$router.replace(
				`/${this.$route.params.random}/Refresh?kiosk=${this.$route.params.random}`,
			)
		},
		judgeHasStorePermission(data) {
			const { permissionList, storeList } = data
			if (
				permissionList.includes('MANAGER') ||
				permissionList.includes('SUPER_MANAGER')
			) {
				return true
			}
			return storeList.some(store => store.id === this.storeId)
		},
		async handleGuestBuyer() {
			try {
				const response = await this.$api.user.getGuestId()
				this.$store.commit('kiosk/SET_IS', false)
				this.$store.commit('user/SET_GUEST_ID', response.data.account)
				this.$store.commit('user/SET_XSRF_TOKEN', response.xsrfToken)
			} catch (err) {
				this.$store.commit('kiosk/SET_IS', false)
				this.$store.commit('user/SET_GUEST_ID', null)
				this.$store.commit('user/SET_XSRF_TOKEN', '')
				if (this.$store.state.maintenance.modal) {
					return
				}
				location.reload()
			}
		},
		getOpenInfo() {
			if (this.storeId == null) return
      return this.$api.getOpenInfo(this.storeId).then(res => {
				this.$store.commit('store/SET_TAKE_OPEN_INFO', res.data)
			})
		},
		initWebSocket() {
			if (
				this.storeId == null ||
				!this.isGuest ||
				this.$store.state.socket.socket != null
			)
				return
			this.$store.dispatch('socket/initWebSocket')
		},
		onCloseCancelOrderDialog() {
			this.$store.state.isCancelOrderDialog = false
		},
		// 檢查WhatsEat店家綁定狀態與優惠卷
		checkWhatsEatBinding() {
			if (
				!this.$store.state.user.showWhatsEatCoupon ||
				!this.$store.state.store.openInfo?.whatsEatSwitch
			) {
				// 如果使用者關閉提示，就直接不取資料
				return
			}
      return this.$whatsEatApi
        .checkBinding(this.storeId)
        .then(res => {
          if (res.status === 200) {
            const count = res.data.data.availableTickets?.length || 0
            const shopId = res.data.data.binding.shopId
            this.$store.commit('store/SET_WHATS_EAT_SHOP_ID', shopId)
            this.$store.commit('store/SET_WHATS_EAT_COUPON_AMOUNT', count)
          } else {
            this.$store.commit('store/SET_WHATS_EAT_SHOP_ID', false)
            this.$store.commit('store/SET_WHATS_EAT_COUPON_AMOUNT', null)
          }
        })
        .catch(() => {
          // 若未綁定則返回404，清空WhatsEat相關狀態變數
          this.$store.commit('store/SET_WHATS_EAT_SHOP_ID', false)
          this.$store.commit('store/SET_WHATS_EAT_COUPON_AMOUNT', null)
        })

		},
	},
}
</script>

<style lang="sass" scoped></style>
